import { Suspense, lazy, useEffect, useMemo } from 'react';

import Tooltip from '@components/Tooltip/Tooltip';
import { isAuthenticatedSelector } from '@store/auth/selectors';
import { AppInit } from '@store/initiation/features/app-init/app-init';
import disableVerticalSwipe from '@utils/disable-vertical-swipe';
import expandWindow from '@utils/expand-window';
import 'base.scss';
import { useDispatch, useSelector } from 'react-redux';
import LogoScreen from 'screens/logo/LogoScreen';

const MainRouter = lazy(async () => {
  await Promise.all([
    import('@store/initiation/sagas'),
    import('@store/auth/module'),
    import('@store/languages/module'),
    import('@store/meetings/module'),
    import('@store/profile/module'),
    import('@store/topics/module'),
    import('@store/app-notifications/module'),
    import('@store/alerts/module'),
    import('@store/users/module'),
    import('@store/user-bots/module'),
  ]);

  return import('routing/routers/MainRouter');
});

const App = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const dispatch = useDispatch();
  const routing = useMemo(() => <MainRouter />, []);

  useEffect(() => {
    dispatch(AppInit.action());
    expandWindow();
    disableVerticalSwipe();
    // handleViewportChange();
  }, [dispatch]);

  if (!isAuthenticated) {
    return <LogoScreen />;
  }

  return (
    <Suspense fallback={<LogoScreen />}>
      <>
        {routing}
        <Tooltip />
      </>
    </Suspense>
  );
};

export default App;
